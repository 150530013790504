/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useAuth, AuthStatus } from '@chordcommerce/gatsby-theme-performance'
import PageSpinner from '~/components/Generic/PageSpinner'
import AccountLoginPage from '~/components/Account/Login/Page'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isLoggedIn, status } = useAuth()

  // User is not logged in
  if (!isLoggedIn && location.pathname !== '/account/login/') {
    // We've already checked and confirmed user is not logged in
    if (status === AuthStatus.Failed || status === AuthStatus.Idle) {
      return <AccountLoginPage />
    }

    // We're currently checking if user is logged in
    return <PageSpinner />
  }

  // User is logged in
  return <Component {...rest} />
}

export default PrivateRoute
