/** @jsx jsx */
import { Box, Container, Heading, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import AuthRegisterForm from '~/components/Account/Register/Form'
import Metadata from '~/components/Metadata'

const AccountRegisterPage = () => {
  const translate = useTranslate()

  return (
    <Container>
      <Metadata title={translate('account.register.page_title')} />
      <Heading
        as="h1"
        variant="h1"
        sx={{
          textAlign: 'center',
          marginTop: ['16px', null, '36px'],
          marginBottom: ['8px', null, '32px']
        }}
      >
        {translate('account.register.page_title')}
      </Heading>
      <Box
        sx={{
          margin: ['0 auto 1.5rem auto', null, '42px auto 4.5rem auto'],
          maxWidth: '600px'
        }}
      >
        <AuthRegisterForm />
      </Box>
    </Container>
  )
}

export default AccountRegisterPage
