/** @jsx jsx */
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { jsx, Text } from 'theme-ui'

const AccountAddressesAddressText = ({ address }) => {
  if (!address) return null

  const name = [address.firstName, address.lastName].filter(name => name)
  const displayName = name.join(' ')
  const cityStateZip = [address.city, address.provinceCode, address.zip]
  const displayCityStateZip = cityStateZip.join(' ')

  return (
    <Fragment>
      {displayName && <Text>{displayName}</Text>}
      {address.company && <Text>{address.company}</Text>}
      {address.address1 && <Text>{address.address1}</Text>}
      {address.address2 && <Text>{address.address2}</Text>}
      {displayCityStateZip && <Text>{displayCityStateZip}</Text>}
      {address.country && <Text>{address.country}</Text>}
    </Fragment>
  )
}

AccountAddressesAddressText.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
    countryCodeV2: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    province: PropTypes.string,
    provinceCode: PropTypes.string,
    zip: PropTypes.string
  })
}

export default AccountAddressesAddressText
